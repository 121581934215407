/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap'); */

@font-face {
  font-family: 'Gill Sans';
  src: url('./assets/fonts/GillSans-Bold.woff2') format('woff2'),
    url('./assets/fonts/GillSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Skia';
  src: url('./assets/fonts/Skia-Regular.woff2') format('woff2'),
    url('./assets/fonts/Skia-Regular.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gill Sans';
  src: url('./assets/fonts/GillSans.woff2') format('woff2'),
    url('./assets/fonts/GillSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --primary-bayleys-color: #00263a;
  --text-color: #474a4c;
  --primary-neo-color: #971b4f;
}

html {
  height: 100vh;
}

* {
  font-family: var(--font), v-apple-system, BlinkMacSystemFont, avenir next, avenir,
    segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body,
#root {
  color: var(--text-color);
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a,
a:link,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: inherit;
  font-weight: inherit;
}
